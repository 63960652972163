import { t } from '@zupr/i18n'
import { Choice } from '@zupr/types/form'

import { DeliveryMethodType } from '@zupr/types/graphql'
import { dayToLocalDay, Weekday, weekdays } from './datetime'
import { formatPrice } from './price'

export const zipcodeGroups: Choice[] = [
    {
        display_name: 'Heel Nederland (incl. de Waddeneilanden)',
        value: '1000-9999',
    },
    {
        display_name: 'Heel Nederland zonder de Waddeneilanden',
        value: '1000-1790,1800-8880,8900-9159,9170-9999',
    },
    {
        display_name: 'De waddeneilanden',
        value: '1791-1797,8881-8885,8891-8897,8899,9160-9164,9166',
    },
]

export const formatZipcodes = (zipcodes: string) => {
    // if zipcodes is part of a group, return the group name
    const group = zipcodeGroups.find((group) => group.value === zipcodes)
    if (group) {
        return group.display_name
    }
    return zipcodes
}

export const formatDaysShort = (days: Weekday[]) => {
    return days?.map(dayToLocalDay).join(', ') || ''
}

export const formatWhenDelivered = (days: number) => {
    if (days === 0) {
        return 'dezelfde dag'
    }
    if (days === 1) {
        return 'de volgende dag'
    }
    return `in ${days || '?'} werkdagen`
}

interface DeliveryDurationProps {
    last_time_for_order_processing: string
    delay_in_days_for_order_delivery: number
    days_for_order_processing: Weekday[]
}

export const formatDeliveryDuration = ({
    last_time_for_order_processing,
    delay_in_days_for_order_delivery,
    days_for_order_processing,
}: DeliveryDurationProps) => {
    return `${
        !!last_time_for_order_processing
            ? `Voor ${last_time_for_order_processing.substring(0, 5)} uur`
            : 'Vandaag'
    } besteld is ${formatWhenDelivered(
        delay_in_days_for_order_delivery
    )} bezorgd (${formatDaysShort(days_for_order_processing)})`
}

// calculate next available date
// input is todays date and a list of days of the week (monday through sunday) that are available
// output is the next available day of the week
export const nextAvailableDay = (day: Weekday, availableDays: string[]) => {
    // if no available days, return day
    if (availableDays.length === 0) return day

    // if day is available, return day
    if (availableDays.includes(day)) return day

    // if day is not available, find the next available day
    const dayIndex = weekdays.indexOf(day)
    const nextIndex = (dayIndex + 1) % 7
    const nextDay = weekdays[nextIndex]

    return availableDays.includes(nextDay)
        ? nextDay
        : nextAvailableDay(nextDay, availableDays)
}

export const formatDeliveryCost = (deliveryCosts?: number) => {
    if (deliveryCosts === undefined) return t('?')
    if (deliveryCosts === 0) return t('Free')
    return formatPrice(deliveryCosts, 'EUR', 'nl')
}

export const formatDeliveryWithThreshold = (method: DeliveryMethodType) => {
    if (!method.freeDeliveryThreshold) {
        return formatDeliveryCost(method.price)
    }
    return `Gratis vanaf ${formatPrice(
        method.freeDeliveryThreshold
    )}, anders ${formatDeliveryCost(method.price)} verzendkosten`
}

export const formatZipcode = (zipcode: string) => {
    if (!zipcode) return zipcode

    if (zipcode.length === 6) {
        return `${zipcode.substring(0, 4)} ${zipcode.substring(4)}`
    }

    return zipcode
}

export const formatAddress = ({
    address,
    number,
    numberAddition,
    zipcode,
    city,
}: {
    address?: string
    number: string
    numberAddition?: string
    zipcode: string
    city?: string
}) => {
    if (!number || !zipcode) return undefined
    if (!address || !city) return `${zipcode}, ${number}`

    return `${address} ${number}${
        numberAddition ? `-${numberAddition}` : ''
    }, ${zipcode} ${city}`
}

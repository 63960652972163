import { gql } from 'urql'

export const orderQuery = gql`
    query Order($id: UUID!) {
        order(id: $id) {
            id
            orderNumber
            discount
            status
            locationId
            total
            deliveryCosts
            wrappingService
            wrappingCosts
            clientSecret
            subTotal
            orderType
            orderlines {
                quantity
                productId
                pricePerProduct
                variationFields {
                    fieldName
                    fieldValue
                }
                unit
                unitAmount
            }
        }
    }
`

export const informationRequestMutation = gql`
    mutation InformationRequest(
        $locationId: ID!
        $products: [ProductInformationInputType!]!
    ) {
        informationRequest(locationId: $locationId, products: $products) {
            id
            errors {
                field
                messages {
                    message
                    code
                    params {
                        name
                        value
                    }
                }
            }
        }
    }
`

export const createOrderMutation = gql`
    mutation CreateOrder(
        $informationRequestId: UUID
        $locationId: ID!
        $orderType: OrderTypeEnum!
        $orderlines: [OrderlineInputType!]!
        $shoppingAreaSlug: String!
    ) {
        createOrder(
            informationRequestId: $informationRequestId
            locationId: $locationId
            orderType: $orderType
            orderlines: $orderlines
            shoppingAreaSlug: $shoppingAreaSlug
        ) {
            errors {
                field
                messages {
                    message
                    code
                    params {
                        name
                        value
                    }
                }
            }
            result {
                id
            }
        }
    }
`

export const createDeliveryMutation = gql`
    mutation createDelivery(
        $address: String!
        $city: String!
        $company: String
        $countryCode: Country
        $name: String
        $number: String!
        $numberAddition: String
        $zipcode: String!
        $orderId: UUID!
        $deliverInstructions: String
        $deliveryMethodId: ID
    ) {
        createDelivery(
            address: $address
            city: $city
            company: $company
            countryCode: $countryCode
            name: $name
            number: $number
            numberAddition: $numberAddition
            zipcode: $zipcode
            orderId: $orderId
            deliverInstructions: $deliverInstructions
            deliveryMethodId: $deliveryMethodId
        ) {
            errors {
                field
                messages {
                    message
                    code
                    params {
                        name
                        value
                    }
                }
            }
            result {
                status
            }
        }
    }
`

export const setOrderFieldsMutation = gql`
    mutation SetOrderFields(
        $email: String
        $orderId: UUID!
        $invoiceAddress: String
        $invoiceCity: String
        $invoiceCompany: String
        $invoiceCountryCode: Country
        $invoiceName: String
        $invoiceNumber: String
        $invoiceNumberAddition: String
        $invoiceZipcode: String
        $name: String
        $phone: String
        $remarks: String
        $vatReceipt: Boolean
        $wrappingService: Boolean
    ) {
        setOrderFields(
            email: $email
            id: $orderId
            invoiceAddress: $invoiceAddress
            invoiceCity: $invoiceCity
            invoiceCompany: $invoiceCompany
            invoiceCountryCode: $invoiceCountryCode
            invoiceName: $invoiceName
            invoiceNumber: $invoiceNumber
            invoiceNumberAddition: $invoiceNumberAddition
            invoiceZipcode: $invoiceZipcode
            name: $name
            phone: $phone
            remarks: $remarks
            vatReceipt: $vatReceipt
            wrappingService: $wrappingService
        ) {
            errors {
                field
                messages {
                    message
                    code
                    params {
                        name
                        value
                    }
                }
            }
            result {
                status
            }
        }
    }
`

export const deliveryStatusOverview = gql`
    query DeliveryStatusOverview($orderId: ID!, $deliverZipcode: String!) {
        deliveryStatusOverview(
            orderId: $orderId
            deliverZipcode: $deliverZipcode
        ) {
            status
            modified
            address
            zipcode
            number
            numberAddition
            city
            countryCode
            name
            company
            plannedDeliveryDate
            estimatedTimeOfArrival
            lastStatusMessage
            deliveryMethodName
            history {
                timestamp
                message
                newCarrierStatus
            }
        }
    }
`

export const confirmOrderMutation = gql`
    mutation ConfirmOrder($id: UUID!) {
        confirmOrder(id: $id) {
            errors {
                field
                messages {
                    message
                    code
                    params {
                        name
                        value
                    }
                }
            }
            result {
                id
                status
            }
        }
    }
`

export const deliveryMethodsQuery = gql`
    query DeliveryMethods($locationId: ID!, $zipcode: String!) {
        deliveryMethods(locationId: $locationId) {
            edges {
                node {
                    id
                    name
                    description
                    daysForOrderDelivery
                    daysForOrderProcessing
                    delayInDaysForOrderDelivery
                    minimumOrderAmount
                    freeDeliveryThreshold
                    zipcodes
                    canDeliver(zipcode: $zipcode)
                    lastTimeForOrderProcessing
                    price
                    plannedDeliveryDate
                }
            }
        }
    }
`

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import classnames from 'classnames'
import React, { useCallback, useContext, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import usePortal from '../../hooks/portal'

import '../../../scss/react/components/tray.scss'

import { ReactComponent as CloseIcon } from '../../../svg/cross.svg'

import ModalContext from '../../context/modal'

const animationSpeed = 300

interface TrayProps {
    children: React.ReactNode
    className?: string
    open?: boolean
    onClose: () => void
    sidebar?: boolean
    right?: boolean
}

const Tray = ({
    children,
    className,
    open,
    onClose,
    sidebar,
    right,
}: TrayProps) => {
    const portalTarget = usePortal('tray')
    const { setTrayOpen } = useContext(ModalContext)

    useEffect(() => {
        setTrayOpen(open)
    }, [open, setTrayOpen])

    const handleClickOverlay = useCallback(
        (ev: React.MouseEvent) => {
            ev.stopPropagation()
            ev.preventDefault()
            onClose()
        },
        [onClose]
    )

    const handleClickContent = useCallback((ev: React.MouseEvent) => {
        ev.stopPropagation()
        ev.preventDefault()
    }, [])

    if (!portalTarget) return null

    return (
        <React.Fragment>
            {ReactDOM.createPortal(
                <div className="is-portaled">
                    <CSSTransition
                        in={open}
                        timeout={{
                            appear: animationSpeed,
                            enter: animationSpeed,
                            exit: animationSpeed,
                        }}
                        classNames="tray-transition"
                        onExit={onClose}
                        appear
                        unmountOnExit
                    >
                        <div
                            className={classnames('tray-overlay', {
                                'tray-sidebar': sidebar,
                                'tray-sidebar-left': sidebar && !right,
                                'tray-sidebar-right': sidebar && right,
                                'tray-top': !sidebar,
                            })}
                            onClick={handleClickOverlay}
                        >
                            <div
                                onClick={handleClickContent}
                                className={classnames(
                                    'tray-content',
                                    className
                                )}
                            >
                                <div className="tray-inner">{children}</div>
                                <button
                                    className="btn btn-micro btn-light tray-close"
                                    type="button"
                                    onClick={onClose}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                        </div>
                    </CSSTransition>
                </div>,
                portalTarget
            )}
        </React.Fragment>
    )
}

export default Tray

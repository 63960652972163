import classnames from 'classnames'
import React from 'react'

import '../../../scss/react/components/badge.scss'

interface BadgeButtonProps
    extends Omit<
        React.HTMLProps<HTMLButtonElement>,
        'dangerouslySetInnerHTML'
    > {
    children?: React.ReactNode
    className?: string
    warning?: boolean
    neutral?: boolean
    info?: boolean
    counter?: boolean
    success?: boolean
}

export const Button = ({
    children,
    className,
    warning,
    neutral,
    info,
    counter,
    success,
    ...props
}: BadgeButtonProps) => (
    <button
        {...props}
        type="button"
        className={classnames('badge', className, {
            'badge-warning': warning,
            'badge-neutral': neutral,
            'badge-info': info,
            'badge-counter': counter,
            'badge-success': success,
        })}
    >
        {children}
    </button>
)

interface BadgeProps {
    children?: React.ReactNode
    className?: string
    warning?: boolean
    neutral?: boolean
    info?: boolean
    counter?: boolean
    success?: boolean
    danger?: boolean
    small?: boolean
    feature?: boolean
    bounce?: boolean
    dark?: boolean
}

const Badge = ({
    children,
    className,
    warning,
    neutral,
    info,
    counter,
    success,
    danger,
    dark,
    small,
    feature,
    bounce,
}: BadgeProps) =>
    (!!small && (
        <small
            className={classnames(className, {
                badge: !feature,
                'badge-feature': feature,
                'badge-warning': warning,
                'badge-neutral': neutral,
                'badge-danger': danger,
                'badge-info': info,
                'badge-dark': dark,
                'badge-counter': counter,
                'badge-success': success,
                'badge-bounce': bounce,
            })}
        >
            {children}
        </small>
    )) || (
        <span
            className={classnames(className, {
                badge: !feature,
                'badge-feature': feature,
                'badge-warning': warning,
                'badge-danger': danger,
                'badge-neutral': neutral,
                'badge-dark': dark,
                'badge-info': info,
                'badge-counter': counter,
                'badge-success': success,
                'badge-bounce': bounce,
            })}
        >
            {children}
        </span>
    )

export const LoadingBadge = (props) => (
    <Badge {...props} className={classnames('badge-loading', props.className)}>
        <span className="loading" />
    </Badge>
)

export default Badge
